<template>
	<page-container :page="page">
		<heading-image
			v-if="page && page.headingImage"
			:heading-image="{
				image: page.headingImage,
				imageWebp: page.headingImageWebp,
				imagePortrait: page.headingImagePortrait,
				imagePortraitWebp: page.headingImagePortraitWebp,
			}"
		/>

		<main-content
			:title="page.title || page.header"
			:subtitle="page.subtitle"
			:content="page.content"
			:right-content="page.header"
		>
			<template #rightContent>
				<div class="columns column6 right-content">
					<picture>
						<source :srcset="defaults[locale].website.indexIntroImageWebp" type="image/webp" />
						<source :srcset="defaults[locale].website.indexIntroImage" />
						<img
							:src="defaults[locale].website.indexIntroImage"
							:alt="defaults[locale].website.indexIntroImageAlt"
							loading="lazy"
						/>
					</picture>
				</div>
			</template>
			<template #extraContent>
				<div class="row-bg-section" />
			</template>
		</main-content>

		<section class="main-content usp">
			<div class="row align-center">
				<div v-for="(benefit, index) in defaults[locale].website.benefit" :key="index" class="columns column4">
					<font-awesome-icon :icon="benefit.icon" size="1x" />
					<div v-parse-links v-html="benefit.content" />
				</div>
			</div>
		</section>

		<section class="main-content room-section">
			<div class="row row-header">
				<div class="columns column12 align-center">
					<h4 class="subtitle">
						{{ defaults[locale].website.promotionsSubtitle }}
					</h4>
					<div v-parse-links v-html="defaults[locale].website.promotionsContent" />
				</div>
			</div>
			<div class="row rooms align-center">
				<div v-for="promo in promoblocksData" :key="promo.ID" class="columns column6">
					<nuxt-link :to="localePath(`/${promo.link}`)" class="room-image">
						<picture>
							<source :srcset="promo.imageWebp" type="image/webp" />
							<source :srcset="promo.image" />
							<img :src="promo.image" :alt="promo.imageAlt" loading="lazy" />
						</picture>
					</nuxt-link>
					<div class="content">
						<h3>
							<nuxt-link :to="localePath(`/${promo.link}`)">
								{{ promo.header }}
							</nuxt-link>
						</h3>
						<div v-parse-links v-html="promo.content" />
						<button-container>
							<nuxt-link :to="localePath(`/${promo.link}`)" class="button">
								{{ promo.button }}
							</nuxt-link>
						</button-container>
					</div>
				</div>
				<div class="columns column12 margin-top">
					<nuxt-link :to="localePath('rooms')" class="button-arrow">
						{{ defaults[locale].rooms.more }}
					</nuxt-link>
				</div>
			</div>
		</section>

		<section class="main-content contentblocks-section">
			<div class="row row-header">
				<div class="columns column12 align-center">
					<h4 class="subtitle">
						{{ page.contentBlockSubtitle }}
					</h4>
					<div v-parse-links v-html="page.contentBlockContent" />
				</div>
			</div>
			<div v-if="page.contentblocks">
				<alternating-block v-for="block in page.contentblocks" :key="block.ID" class="row block-item">
					<div class="columns column6">
						<h3>{{ block.header }}</h3>
						<div v-parse-links v-html="block.content" />
					</div>
					<div class="columns column6">
						<picture>
							<source :srcset="block.imageWebp" type="image/webp" />
							<source :srcset="block.image" />
							<img :src="block.image" :alt="block.imageAlt" loading="lazy" />
						</picture>
					</div>
				</alternating-block>
			</div>
		</section>

		<review-slider
			v-if="page.showReviewSlider"
			:header="defaults[locale].website.reviewsHeader"
			:subtitle="defaults[locale].website.reviewsSubtitle"
		/>

		<frequently-asked-questions
			v-if="page.frequentlyAskedQuestion"
			:header="page.frequentlyAskedQuestion.header"
			:items="page.frequentlyAskedQuestion.faqitems"
		/>

		<newsletter
			v-if="page.showNewsletter"
			:header="defaults[locale].newsletterForm.header"
			:content="defaults[locale].newsletterForm.content"
			:button="defaults[locale].newsletterForm.button"
		/>
	</page-container>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchPage();
await fetchDefaults();

const { data: promoblocksData } = await useWebsiteFetch('promoblocks', {
	query: { language: locale.value },
	key: `${locale.value}/promoblocks`,
});
</script>

<style lang="scss" scoped>
h1 {
	margin: 25px 0;
}

.contentblocks-section {
	background: url('~/assets/images/contentblocks-bg-pattern.webp') repeat;
	background-size: cover;
	padding: 75px 0 5px;
}

.row-header {
	max-width: 1040px;
	margin: 0 auto 50px;
}

.margin-top {
	margin-top: 40px;
}

.right-content {
	position: relative;

	img {
		border: 10px solid #fff;
	}
}

#intro {
	position: relative;

	.row {
		z-index: 3;
		position: relative;
	}

	.row-bg-section {
		z-index: 2;
		position: absolute;
		inset: 0;

		&::before {
			content: '';
			display: block;
			position: absolute;
			inset: 140px 0 40px calc(50% + 60px);
			z-index: 1;
			background: $primary-color;
			max-height: calc((50vw / 8) * 6);
		}
	}
}

.rooms {
	margin: 25px auto 50px;
	display: flex;
	flex-flow: wrap row;
	align-items: stretch;
	justify-content: center;

	.column6 {
		margin: 20px auto;
		flex-flow: wrap column;
		align-items: stretch;
		justify-content: center;
		position: relative;

		&::after {
			content: '';
			display: block;
			background: #fff;
			position: absolute;
			inset: 0 30px;
			z-index: 1;
		}

		.content,
		.room-image {
			position: relative;
			z-index: 2;
		}
	}

	img {
		border: 10px solid #fff;
	}
}

.usp {
	position: relative;

	.row {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: stretch;
	}

	.column4 {
		background: #f8e2da;
		border: 10px solid #fff;
		width: 32%;
		padding: 40px 30px;
	}

	svg {
		color: $primary-color;
		font-size: 60px;
		margin: 5px 0 20px;
	}
}

@media (max-width: 960px) {
	.usp {
		width: 100%;

		.row {
			flex-flow: wrap column;
		}

		.column4 {
			width: 94%;
			margin: 20px auto;
		}
	}

	.rooms .column6 {
		margin: 0 auto 25px;

		&::after {
			inset: 25px;
		}
	}

	.row-bg-section {
		position: relative;

		&::before {
			height: 75vw;
			max-height: 100vw !important;
			top: auto !important;
			left: 60px !important;
			bottom: 0 !important;
		}
	}
}
</style>
